import React, { useState } from 'react';
import { database, ref, get } from '../firebase';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';

function Login({ setIsLoggedIn }) {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const usersRef = ref(database, 'users');
      const snapshot = await get(usersRef);
      if (snapshot.exists()) {
        const users = Object.values(snapshot.val());
        const user = users.find((user) => user.phoneNumber === phoneNumber && user.password === password);
        if (user) {
          // User is authenticated, redirect to dashboard
          localStorage.setItem('token', 'logged-in');
          setIsLoggedIn(true);
          toast.success('Login successful!');
          navigate('/'); // Redirect to the / route
        } else {
          setError('Invalid phone number or password');
        }
      } else {
        setError('No users found');
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div style={{ maxWidth: '400px', margin: '40px auto', padding: '20px', backgroundColor: '#f9f9f9', border: '1px solid #ddd', borderRadius: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
      <h2 style={{ textAlign: 'center', marginBottom: '20px', fontSize: '24px', fontWeight: 'bold' }}>Login</h2>
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
          <label style={{ fontSize: '18px', marginRight: '10px' }}>Phone Number:</label>
          <input
            type="number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            style={{ width: '70%', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', fontSize: '18px' }}
            placeholder="Enter your phone number"
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
          <label style={{ fontSize: '18px', marginRight: '10px' }}>Password:</label>
          <input
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ width: '70%', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', fontSize: '18px' }}
            placeholder="Enter your password"
          />
          <span style={{ cursor: 'pointer', marginLeft: '10px', fontSize: '18px' }} onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? 'Hide' : 'Show'}
          </span>
        </div>
        <button type="submit" style={{ width: '100%', height: '40px', padding: '10px', border: 'none', borderRadius: '5px', backgroundColor: '#4CAF50', color: '#fff', cursor: 'pointer', fontSize: '18px' }}>
          Login
        </button>
        {error && <p style={{ color: 'red', fontSize: '18px' }}>{error}</p>}
      </form>

      <ToastContainer />
    </div>
  );
}

export default Login;