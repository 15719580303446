import React, { useState, useEffect } from "react";
import { database, ref, set, get, push } from "../firebase";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from './assest/logo.jpg';
import numwords  from 'num-words';

function InvestmentForm() {
  const [paymentMode, setPaymentMode] = useState("cash");
  const [option, setOption] = useState("");
  const [receiptNo, setReceiptNo] = useState(1);
  const [formData, setFormData] = useState({
    receiptNo: "",
    date: "",
    refName: "",
    investorName: "",
    nameOfRemittanceCustomer: "",
    nameOfRemittanceCustomerOther: "",
    remmitFromPayoutCycle: "",
    remmitAmount: "",
    newAddAmountForFullPayment: "",
    paymentMode: "",
    accountName: "",
    bankName: "",
    accountNo: "",
    branch: "",
    ifscCode: "",
    googlePayNo: "",
    accountType: "",
    totalAmountInNumber: "",
    totalAmountInWord: "",
    takenBy: "",
    customerSignature: "",
    cycle: "",
    isRemittanceCustomer: "",
  });

  const [customers, setCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [remmitFromPayoutCycle, setRemmitFromPayoutCycle] = useState("");
   const [refSearchTerm, setRefSearchTerm] = useState("");
   const [refSearchResults, setRefSearchResults] = useState([]);
  useEffect(() => {
    const counterRef = ref(database, "receiptCounters");
    get(counterRef).then((snapshot) => {
      if (snapshot.exists()) {
        const receiptNumber = snapshot.val();
        if (typeof receiptNumber !== 'string' && typeof receiptNumber !== 'number') {
          console.error('receiptNumber is not a string or a number');
          return;
        }
        setReceiptNo(receiptNumber);
      } else {
        set(counterRef, 1);
        setReceiptNo(1);
      }
    });
  }, []);

  useEffect(() => {
    const fetchCustomers = async () => {
      const customersRef = ref(database, "Reinvestments");
      const snapshot = await get(customersRef);
      if (snapshot.exists()) {
        const customers = Object.values(snapshot.val());
        setCustomers(customers);
      } else {
        console.log("No customers available in the database.");
      }
    };

    fetchCustomers();
  }, []);

  useEffect(() => {
    if (isFormSubmitted) {
      const investmentRef = ref(database, `Reinvestments/${receiptNo}`);
      set(investmentRef, formData)
        .then(() => {
          toast.success("Form submitted successfully!");
          clearForm(); // Call clearForm here
          setIsFormSubmitted(false);
          setReceiptNo(receiptNo + 1);
          set(ref(database, "receiptCounters"), receiptNo + 1);
        })
        .catch((error) => toast.error("Error: " + error.message));
    }
  }, [isFormSubmitted, formData, receiptNo]);

  const handlePaymentModeChange = (e) => {
    setPaymentMode(e.target.value);
  };

  const handleOptionChange = (e) => {
    setOption(e.target.value);
  };

 

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'remmitAmount' || name === 'newAddAmountForFullPayment') {
      const remmitAmount = parseFloat(formData.remmitAmount || 0);
      const newAddAmountForFullPayment = parseFloat(formData.newAddAmountForFullPayment || 0);
      const currentValue = parseFloat(value || 0); // Handle empty string case
      const totalAmount = remmitAmount + newAddAmountForFullPayment + currentValue - parseFloat(formData[name] || 0);
      const totalAmountInWords =  numwords(totalAmount) ; // Add "Indian Rupees" prefix
      setFormData((prev) => ({ ...prev, [name]: value, totalAmountInNumber: totalAmount, totalAmountInWord: totalAmountInWords }));
    } else if (name === 'option') {
      setFormData((prev) => ({ ...prev, cycle: value }));
    } else if (name === 'remmitFromPayoutCycle') {
      setFormData((prev) => ({ ...prev, remmitFromPayoutCycle: value }));
    } else if (name === 'isRemittanceCustomer') {
      setFormData((prev) => ({ ...prev, isRemittanceCustomer: value }));
      if (value === 'yes') {
        setFormData((prev) => ({ ...prev, nameOfRemittanceCustomerOther: '' }));
      } else {
        setFormData((prev) => ({ ...prev, nameOfRemittanceCustomerOther: '' }));
      }
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (typeof receiptNo !== 'string' && typeof receiptNo !== 'number') {
      console.error('receiptNo is not a string or a number');
      return;
    }
    setFormData((prev) => ({ ...prev, receiptNo, paymentMode, cycle: option, remmitFromPayoutCycle }));
    setIsFormSubmitted(true);
  };


  const clearForm = () => {
    setFormData({
      receiptNo: "",
      date: "",
      refName: "",
      investorName: "",
      nameOfRemittanceCustomer: "",
      nameOfRemittanceCustomerOther: "",
      remmitFromPayoutCycle: "",
      remmitAmount: "",
      newAddAmountForFullPayment: "",
      paymentMode: "",
      accountName: "",
      bankName: "",
      accountNo: "",
      branch: "",
      ifscCode: "",
      googlePayNo: "",
      accountType: "",
      totalAmountInNumber: "",
      totalAmountInWord: "",
      takenBy: "",
      customerSignature: "",
      cycle: "",
      isRemittanceCustomer: "no",
    });
    setOption("");
    setRemmitFromPayoutCycle("");
    setPaymentMode("cash");
  };

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

    const handleRefSearchTermChange = (e) => {
      setRefSearchTerm(e.target.value);
      setFormData((prev) => ({ ...prev, ref: e.target.value }));
      const fetchRefSearchResults = async () => {
        if (e.target.value.trim() !== "") {
          const customersRef = ref(database, "customers");
          const snapshot = await get(customersRef);
          if (snapshot.exists()) {
            const customers = Object.values(snapshot.val());
            const searchResults = customers.filter(customer => customer.customerName.toLowerCase().includes(e.target.value.toLowerCase()));
            setRefSearchResults(searchResults);
          } else {
            console.log("No customers available in the database.");
          }
        } else {
          setRefSearchResults([]);
        }
      };
      fetchRefSearchResults();
    };

  
const handleRemmitFromPayoutCycleChange = (e) => {
  setRemmitFromPayoutCycle(e.target.value);
};

  const printForm = (customer) => {
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    document.body.appendChild(iframe);
  
    iframe.contentWindow.document.write(`
      <html>
        <head>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 20px;
            }
            .header {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 24px;
              font-weight: bold;
              margin-bottom: 20px;
              color: rgb(50, 52, 54);
            }
            .logo-container {
              width: 80px;
              height: 80px;
              
              overflow: hidden;
              margin-right: 20px;
             
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .logo-container img {
              width: 70%;
              height: auto;
            }
            .form-table {
              width: 100%;
              border-collapse: collapse;
              border: 1px solid #ddd;
            }
            .form-table th, .form-table td {
              border: 1px solid #ddd;
              padding: 10px;
              text-align: left;
            }
            .signature {
              margin-top: 200px;
              text-align: right;
              color: rgb(0, 0, 0);
            }
            .table-header {
              background-color: rgb(88, 84, 84);
              color: #fff;
              padding: 10px;
              text-align: center;
            }
          </style>
        </head>
        <body>
          <div class="header">
            <div class="logo-container">
               <img src=${logo} alt="UNICO Logo" />
            </div>
            <div>UNICO EMPRESA</div>
          </div>
        
          <table class="form-table">
            <tr class="table-header">
              <th colspan="6">Investment Details</th>
            </tr>
            <tr>
              <th>RECEIPT NO:</th>
              <td colspan="3">${customer.receiptNo}</td>
              <th>DATE:</th>
              <td>${customer.date}</td>
            </tr>
            <tr>
              <th>REF NAME:</th>
              <td colspan="5">${customer.refName}</td>
            </tr>
          </table>
    
          <table class="form-table">
            <tr class="table-header">
              <th colspan="6">Investor Details</th>
            </tr>
            <tr>
              <th>INVESTOR NAME:</th>
              <td colspan="3">${customer.investorName}</td>
            </tr>
            <tr>
              <th>NAME OF REMITTANCE CUSTOMER:</th>
              <td colspan="3">${customer.nameOfRemittanceCustomer}</td>
            </tr>
            ${customer.isRemittanceCustomer === "yes" ? `
            <tr>
              <th>NAME OF REMITTANCE CUSTOMER (OTHER):</th>
              <td colspan="3">${customer.nameOfRemittanceCustomerOther}</td>
            </tr>
            ` : ''}
            <tr>
              <th>REMMIT FROM PAYOUT CYCLE:</th>
              <td colspan="3">${customer.remmitFromPayoutCycle}</td>
            </tr>
            <tr>
              <th>REMMIT AMOUNT:</th>
              <td colspan="3">${customer.remmitAmount}</td>
            </tr>
            <tr>
              <th>NEW ADD AMOUNT FOR FULL PAYMENT:</th>
              <td colspan="3">${customer.newAddAmountForFullPayment}</td>
            </tr>
        
            <tr>
              <th>MODE OF PAYMENT:</th>
              <td colspan="3">${customer.paymentMode}</td>
            </tr>
          </table>
    
          ${customer.paymentMode === "online" ? `
            <table class="form-table">
              <tr class="table-header">
                <th colspan="6">Bank Account Details</th>
              </tr>
              <tr>
                <th>ACCOUNT NAME:</th>
                <td colspan="3">${customer.accountName}</td>
              </tr>
              <tr>
                <th>BANK NAME:</th>
                <td colspan="3">${customer.bankName}</td>
              </tr>
              <tr>
                <th>ACCOUNT NO:</th>
                <td colspan="3">${customer.accountNo}</td>
              </tr>
              <tr>
                <th>BRANCH:</th>
                <td colspan="3">${customer.branch}</td>
              </tr>
              <tr>
                <th>IFSC CODE:</th>
                <td colspan="3">${customer.ifscCode}</td>
              </tr>
              <tr>
                <th>GOOGLE PAY NO:</th>
                <td colspan="3">${customer.googlePayNo}</td>
              </tr>
              <tr>
                <th>ACCOUNT TYPE:</th>
                <td colspan="3">${customer.accountType}</td>
              </tr>
              <tr>
                <th>TOTAL AMOUNT IN NUMBER:</th>
                <td colspan="3">${customer.totalAmountInNumber}</td>
              </tr>
              <tr>
                <th>TOTAL AMOUNT IN WORD:</th>
                <td colspan="3">${customer.totalAmountInWord}</td>
              </tr>
            </table>
          ` : ''}
  
          <table class="form-table">
            <tr class="table-header">
              <th colspan="6">Additional Information</th>
            </tr>
            <tr>
              <th>TAKEN BY:</th>
              <td colspan="3">${customer.takenBy}</td>
            </tr>
          </table>
    
          <div class="signature">Customer Signature: ${customer.investorName}</div>
        </body>
      </html>
    `);
  
    iframe.contentWindow.print();
    // iframe.remove();
  };

  const updateCustomerForm = (customer) => {
    setFormData(customer);
  };

  const deleteCustomer = (customer) => {
    const customerRef = ref(database, `Reinvestments/${customer.receiptNo}`);
    set(customerRef, null)
      .then(() => {
        toast.success("Customer deleted successfully!");
        const updatedCustomers = customers.filter((c) => c.receiptNo !== customer.receiptNo);
        setCustomers(updatedCustomers);
      })
      .catch((error) => toast.error("Error: " + error.message));
  };

  const cellStyle = {
    border: "1px solid black",
    padding: "8px",
  };

  const inputStyle = {
    padding: "8px",
    width: "80%",
    fontSize: "14px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  };

  return (
    <div style={{ margin: "20px", fontFamily: "Arial, sans-serif" }}>
      <form onSubmit={handleSubmit}>
        <table
          style={{
            border: "1px solid black",
            borderCollapse: "collapse",
            width: "100%",
          }}
        >
          <thead>
            <tr>
              <th style={cellStyle}>RECEIPT NO:</th>
              <th style={cellStyle} colSpan="2">
                <input
                  type="text"
                  value={receiptNo}
                  readOnly
                  style={inputStyle}
                />
              </th>
              <th style={cellStyle}>DATE:</th>
              <th style={cellStyle}>
                <input type="date" name="date" onChange={handleChange} style={inputStyle} />
              </th>
              <th style={cellStyle}>CYCLE</th>
              <th style={cellStyle}>
                <select
                  value={option}
                  onChange={handleOptionChange}

                >
                            <option value="">Select Option</option>
      <option value="1-10">1-10</option>
      <option value="11-15">11-15</option>
      <option value="15-20">15-20</option>
      <option value="21-30">21-30</option>
                </select>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={cellStyle}>REF NAME:</td>
                            <td style={cellStyle}>
                              <input type="text" name="ref" value={formData.ref} onChange={handleRefSearchTermChange} style={inputStyle} />
                              {refSearchResults.length > 0 && (
                                <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                                  {refSearchResults.map((customer) => (
                                    <li key={customer.formNo} style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>
                                      <span onClick={() => {
                                        setFormData((prev) => ({ ...prev, ref: customer.customerName }));
                                        setRefSearchTerm(customer.customerName);
                                        setRefSearchResults([]);
                                      }}>{customer.customerName}</span>
                                    </li>
                                  ))}
                                  <li key="no-match" style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>
                                    <span onClick={() => {
                                      setFormData((prev) => ({ ...prev, ref: refSearchTerm }));
                                      setRefSearchResults([]);
                                    }}>No match found, use this value</span>
                                  </li>
                                </ul>
                              )}
                            </td>
            </tr>
            <tr>
              <td style={cellStyle}>INVESTOR NAME:</td>
              <td style={cellStyle} colSpan="6">
                <input type="text" name="investorName" onChange={handleChange} style={inputStyle} />
              </td>
            </tr>
            <tr>
              <td style={cellStyle}>NAME OF REMITTANCE CUSTOMER:</td>
              <td style={cellStyle} colSpan="6">
                <select name="isRemittanceCustomer" onChange={handleChange} style={inputStyle}>
                  <option value="no">No</option>
                  <option value="yes">Yes</option>
                </select>
              </td>
            </tr>
            {formData.isRemittanceCustomer === "yes" && (
              <tr>
                <td style={cellStyle}>NAME OF REMITTANCE CUSTOMER (OTHER):</td>
                <td style={cellStyle} colSpan="6">
                  <input type="text" name="nameOfRemittanceCustomerOther" onChange={handleChange} style={inputStyle} />
                </td>
              </tr>
            )}
            <tr>
              <td style={cellStyle}>REMMIT FROM PAYOUT CYCLE:</td>
              <td style={cellStyle}>
                <select
                  value={remmitFromPayoutCycle}
                  onChange={handleRemmitFromPayoutCycleChange}
                  style={inputStyle}
                >
                  <option value="">Select Option</option>
                  <option value="1-10">1-10</option>
                  <option value="11-15">11-15</option>
                  <option value="15-20">15-20</option>
                  <option value="21-30">21-30</option>
                </select>
              </td>
              <td style={cellStyle}>REMMIT DATE:</td>
              <td style={cellStyle}>
              <input type="date" name="date" onChange={handleChange} style={inputStyle} />
              </td>
            </tr>
            <tr>
              <td style={cellStyle}>REMMIT AMOUNT:</td>
              <td style={cellStyle} colSpan="6">
                <input type="number" name="remmitAmount" onChange={handleChange} style={inputStyle} />
              </td>
            </tr>
            <tr>
              <td style={cellStyle}>NEW ADD AMOUNT FOR FULL PAYMENT:</td>
              <td style={cellStyle} colSpan="6">
                <input type="number" name="newAddAmountForFullPayment" onChange={handleChange} style={inputStyle} />
              </td>
            </tr>
            <tr>
              <td style={cellStyle}>MODE OF PAYMENT:</td>
              <td style={cellStyle}>
                <select
                  value={paymentMode}
                  onChange={handlePaymentModeChange}
                  style={inputStyle}
                >
                  <option value="cash">Cash</option>
                  <option value="online">Online</option>
                </select>
              </td>
              <td style={cellStyle}>
                {paymentMode === "online" ? "Enter Bank Details" : ""}
              </td>
            </tr>
            {paymentMode === "online" && (
              <>
                <tr>
                  <td style={cellStyle} colSpan="7">
                    <h3>Bank Account Details</h3>
                  </td>
                </tr>
                <tr>
                  <td style={cellStyle}>ACCOUNT NAME:</td>
                  <td style={cellStyle} colSpan="3">
                    <input type="text" name="accountName" onChange={handleChange} style={inputStyle} />
                  </td>
                </tr>
                <tr>
                  <td style={cellStyle}>BANK NAME:</td>
                  <td style={cellStyle} colSpan="6">
                    <input type="text" name="bankName" onChange={handleChange} style={inputStyle} />
                  </td>
                </tr>
                <tr>
                  <td style={cellStyle}>ACCOUNT NO:</td>
                  <td style={cellStyle} colSpan="6">
                    <input type="text" name="accountNo" onChange={handleChange} style={inputStyle} />
                  </td>
                </tr>
                <tr>

                  <td style={cellStyle}>BRANCH:</td>
                  <td style={cellStyle} colSpan="6">
                    <input type="text" name="branch" onChange={handleChange} style={inputStyle} />
                  </td>
                </tr>
                <tr>
                  <td style={cellStyle}>IFSC CODE:</td>
                  <td style={cellStyle} colSpan="6">
                    <input type="text" name="ifscCode" onChange={handleChange} style={inputStyle} />
                  </td>
                </tr>
                <tr>
                  <td style={cellStyle}>GOOGLE PAY NO:</td>
                  <td style={cellStyle} colSpan="3">
                    <input type="text" name="googlePayNo" onChange={handleChange} style={inputStyle} />
                  </td>
                  <td style={cellStyle}>ACCOUNT TYPE:</td>
                  <td style={cellStyle} colSpan="2">
                    <input type="text" name="accountType" onChange={handleChange} style={inputStyle} />
                  </td>
                </tr>
              </>
            )}
            <tr>
              <td style={cellStyle}>TOTAL AMOUNT IN NUMBER:</td>
              <td style={cellStyle} colSpan="6">
                <input type="number" value={formData.totalAmountInNumber} readOnly onChange={handleChange} style={inputStyle} />
              </td>
            </tr>
            <tr>
              <td style={cellStyle}>TOTAL AMOUNT IN WORD:</td>
              <td style={cellStyle} colSpan="6">
                <input type="text" value={formData.totalAmountInWord} readOnly onChange={handleChange} style={inputStyle} />
              </td>
            </tr>
            <tr>
              <td style={cellStyle}>TAKEN BY:</td>
              <td style={cellStyle} colSpan="4">
                <input type="text" name="takenBy" onChange={handleChange} style={inputStyle} />
              </td>
            </tr>
          </tbody>
        </table>
        <button type="submit" style={{ padding: "10px", backgroundColor: "#4CAF50", color: "white", border: "none", borderRadius: "5px", cursor: "pointer", fontSize: "16px", width: "50%", display: "block", margin: "0 auto" }}>
          Submit
        </button>
      </form>

      <div>
        <h2>Search Customers</h2>
        <input type="text" value={searchTerm} onChange={handleSearchTermChange} placeholder="Search by receipt no or investor name" />
      </div>

      <table
        style={{
          border: "1px solid black",
          borderCollapse: "collapse",
          width: "100%",
        }}
      >
        <thead>
          <tr>
            <th style={cellStyle}>Receipt No</th>
            <th style={cellStyle}>Investor Name</th>
            <th style={cellStyle}>Amount</th>
            <th style={cellStyle}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {customers.filter(customer => customer.receiptNo.toString().includes(searchTerm) || customer.investorName.toLowerCase().includes(searchTerm.toLowerCase())).map(customer => (
            <tr key={customer.receiptNo}>
              <td style={cellStyle}>{customer.receiptNo}</td>
              <td style={cellStyle}>{customer.investorName}</td>
              <td style={cellStyle}>{customer.totalAmountInNumber}</td>
              <td style={cellStyle}>
                <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                  <button onClick={() => printForm(customer)} style={{ backgroundColor: '#4CAF50', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer', padding: '10px 20px', fontSize: '16px', display: 'inline-block', verticalAlign: 'middle' }}>
                    <i class="fa-solid fa-print"></i>
                  </button>
                  <button onClick={() => updateCustomerForm(customer)} style={{ backgroundColor: '#03A9F4', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer', padding: '10px 20px', fontSize: '16px', display: 'inline-block', verticalAlign: 'middle', marginLeft: '10px' }}>
                    <i className="fas fa-edit"></i>
                  </button>
                  <button onClick={() => deleteCustomer(customer)} style={{ backgroundColor: '#FF0000', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer', padding: '10px 20px', fontSize: '16px', display: 'inline-block', verticalAlign: 'middle', marginLeft: '10px' }}>
                    <i className="fas fa-trash-alt"></i>
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ToastContainer />
    </div>
  );
}

export default InvestmentForm;