import React, { useState, useEffect } from "react";
import { database, ref, set, get, push } from "../firebase";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from './assest/logo.jpg'
import numWords from "num-words";

function InvestmentForm() {
  const [paymentMode, setPaymentMode] = useState("cash");
  const [option, setOption] = useState("");
  const [receiptNo, setReceiptNo] = useState(1);
  const [formData, setFormData] = useState({
    receiptNo: "",
    date: "",
    refName: "",
    investorName: "",
    amountInNumber: "",
    amountInWord: "",
    paymentMode: "",
    accountName: "",
    bankName: "",
    accountNo: "",
    branch: "",
    ifscCode: "",
    googlePayNo: "",
    accountType: "",
    takenBy: "",
    customerSignature: "",
  });

  const [customers, setCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [refSearchTerm, setRefSearchTerm] = useState("");
  const [refSearchResults, setRefSearchResults] = useState([]);

  useEffect(() => {
    const counterRef = ref(database, "receiptCounter");
    get(counterRef).then((snapshot) => {
      if (snapshot.exists()) {
        const receiptNumber = snapshot.val();
        if (typeof receiptNumber !== 'string' && typeof receiptNumber !== 'number') {
          console.error('receiptNumber is not a string or a number');
          return;
        }
        setReceiptNo(receiptNumber);
      } else {
        set(counterRef, 1);
        setReceiptNo(1);
      }
    });
  }, []);

  useEffect(() => {
    const fetchCustomers = async () => {
      const customersRef = ref(database, "investments");
      const snapshot = await get(customersRef);
      if (snapshot.exists()) {
        const customers = Object.values(snapshot.val());
        setCustomers(customers);
      } else {
        console.log("No customers available in the database.");
      }
    };

    fetchCustomers();
  }, []);

  useEffect(() => {
    if (isFormSubmitted) {
      if (isUpdate) {
        const investmentRef = ref(database, `investments/${formData.receiptNo}`);
        set(investmentRef, formData)
          .then(() => {
            toast.success("Form updated successfully!");
            clearForm();
            setIsFormSubmitted(false);
            setIsUpdate(false);
          })
          .catch((error) => toast.error("Error: " + error.message));
      } else {
        const investmentRef = ref(database, `investments/${receiptNo}`);
        set(investmentRef, formData)
          .then(() => {
            toast.success("Form submitted successfully!");
            clearForm();
            setIsFormSubmitted(false);
            setReceiptNo(receiptNo + 1);
            set(ref(database, "receiptCounter"), receiptNo + 1);
          })
          .catch((error) => toast.error("Error: " + error.message));
      }
    }
  }, [isFormSubmitted, formData, receiptNo, isUpdate]);

  const handlePaymentModeChange = (e) => {
    setPaymentMode(e.target.value);
  };

  const handleOptionChange = (e) => {
    setOption(e.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'amountInNumber') {
      const amountInWords = numWords(value);
      setFormData((prev) => ({ ...prev, [name]: value, amountInWord: amountInWords }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  useEffect(() => {
    if (formData.amountInNumber) {
      const amountInWords = numWords(formData.amountInNumber);
      setFormData((prev) => ({ ...prev, amountInWord: amountInWords }));
    }
  }, [formData.amountInNumber]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (typeof receiptNo !== 'string' && typeof receiptNo !== 'number') {
      console.error('receiptNo is not a string or a number');
      return;
    }
    if (isUpdate) {
      setFormData((prev) => ({ ...prev, paymentMode }));
      setIsFormSubmitted(true);
    } else {
      setFormData((prev) => ({ ...prev, receiptNo, paymentMode }));
      setIsFormSubmitted(true);
    }
  };

  const clearForm = () => {
    setFormData({
      receiptNo: "",
      date: "",
      refName: "",
      investorName: "",
      amountInNumber: "",
      amountInWord: "",
      paymentMode: "",
      accountName: "",
      bankName: "",
      accountNo: "",
      branch: "",
      ifscCode: "",
      googlePayNo: "",
      accountType: "",
      takenBy: "",
      customerSignature: "",
    });
    setIsUpdate(false);
  };

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleRefSearchTermChange = (e) => {
    setRefSearchTerm(e.target.value);
    setFormData((prev) => ({ ...prev, ref: e.target.value }));
    const fetchRefSearchResults = async () => {
      if (e.target.value.trim() !== "") {
        const customersRef = ref(database, "customers");
        const snapshot = await get(customersRef);
        if (snapshot.exists()) {
          const customers = Object.values(snapshot.val());
          const searchResults = customers.filter(customer => customer.customerName.toLowerCase().includes(e.target.value.toLowerCase()));
          setRefSearchResults(searchResults);
        } else {
          console.log("No customers available in the database.");
        }
      } else {
        setRefSearchResults([]);
      }
    };
    fetchRefSearchResults();
  };

  const printForm = (customer) => {
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    document.body.appendChild(iframe);
  
    iframe.contentWindow.document.write(`
      <html>
        <head>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 20px;
            }
            .header {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 24px;
              font-weight: bold;
              margin-bottom: 20px;
              color: rgb(50, 52, 54);
            }
            .logo-container {
              width: 80px;
              height: 80px;
              overflow: hidden;
              margin-right: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .logo-container img {
              width: 70%;
              height: auto;
            }
            .form-table {
              width: 100%;
              border-collapse: collapse;
              border: 1px solid #ddd;
            }
            .form-table th, .form-table td {
              border: 1px solid #ddd;
              padding: 10px;
              text-align: left;
            }
            .signature {
              margin-top: 200px;
              text-align: right;
              color: rgb(0, 0, 0);
            }
            .table-header {
              background-color: rgb(88, 84, 84);
              color: #fff;
              padding: 10px;
              text-align: center;
            }
          </style>
        </head>
        <body>
          <div class="header">
            <div class="logo-container">
               <img src=${logo} alt="UNICO Logo" />
            </div>
            <div>UNICO EMPRESA</div>
          </div>
        
          <table class="form-table">
            <tr class="table-header">
              <th colspan="6">Investment Details</th>
            </tr>
            <tr>
              <th>RECEIPT NO:</th>
              <td colspan="3">${customer.receiptNo}</td>
              <th>DATE:</th>
              <td>${customer.date}</td>
            </tr>
            <tr>
              <th>REF NAME:</th>
              <td colspan="5">${customer.refName}</td>
            </tr>
          </table>
    
          <table class="form-table">
            <tr class="table-header">
              <th colspan="6">Investor Details</th>
            </tr>
            <tr>
              <th>INVESTOR NAME:</th>
              <td colspan="3">${customer.investorName}</td>
            </tr>
            <tr>
              <th>AMOUNT IN NUMBER:</th>
              <td colspan="3">${customer.amountInNumber}</td>
            </tr>
            <tr>
              <th>AMOUNT IN WORD:</th>
              <td colspan="3">${customer.amountInWord}</td>
            </tr>
            <tr>
              <th>MODE OF PAYMENT:</th>
              <td colspan="3">${customer.paymentMode}</td>
            </tr>
          </table>
    
          ${customer.paymentMode === "online" ? `
            <table class="form-table">
              <tr class="table-header">
                <th colspan="6">Bank Account Details</th>
              </tr>
              <tr>
                <th>ACCOUNT NAME:</th>
                <td colspan="3">${customer.accountName}</td>
              </tr>
              <tr>
                <th>BANK NAME:</th>
                <td colspan="3">${customer.bankName}</td>
              </tr>
              <tr>
                <th>ACCOUNT NO:</th>
                <td colspan="3">${customer.accountNo}</td>
              </tr>
              <tr>
                <th>BRANCH:</th>
                <td colspan="3">${customer.branch}</td>
              </tr>
              <tr>
                <th>IFSC CODE:</th>
                <td colspan="3">${customer.ifscCode}</td>
              </tr>
              <tr>
                <th>GOOGLE PAY NO:</th>
                <td colspan="3">${customer.googlePayNo}</td>
              </tr>
              <tr>
                <th>ACCOUNT TYPE:</th>
                <td colspan="3">${customer.accountType}</td>
              </tr>
            </table>
          ` : ''}
  
          <table class="form-table">
            <tr class="table-header">
              <th colspan="6">Additional Information</th>
            </tr>
            <tr>
              <th>TAKEN BY:</th>
              <td colspan="3">${customer.takenBy}</td>
            </tr>

          </table>
    
          <div class="signature">Customer Signature: ${customer.investorName}</div>
        </body>
      </html>
    `);
  
    iframe.contentWindow.print();
  };

  const updateCustomerForm = (customer) => {
    setFormData(customer);
    setPaymentMode(customer.paymentMode);
    setIsUpdate(true);
  };

  const deleteCustomer = (customer) => {
    const customerRef = ref(database, `investments/${customer.receiptNo}`);
    set(customerRef, null)
      .then(() => {
        toast.success("Customer deleted successfully!");
        const updatedCustomers = customers.filter((c) => c.receiptNo !== customer.receiptNo);
        setCustomers(updatedCustomers);
      })
      .catch((error) => toast.error("Error: " + error.message));
  };

  const cellStyle = {
    border: "1px solid black",
    padding: "8px",
  };

  const inputStyle = {
    padding: "8px",
    width: "80%",
    fontSize: "14px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  };

  return (
    <div style={{ margin: "20px", fontFamily: "Arial, sans-serif" }}>
      <form onSubmit={handleSubmit}>
        <table
          style={{
            border: "1px solid black",
            borderCollapse: "collapse",
            width: "100%",
          }}
        >
          <thead>
            <tr>
              <th style={cellStyle}>RECEIPT NO:</th>
              <th style={cellStyle} colSpan="2">
                <input
                  type="text"
                  value={receiptNo}
                  readOnly
                  style={inputStyle}
                />
              </th>
              <th style={cellStyle}>DATE:</th>
              <th style={cellStyle}>
                <input type="date" name="date" value={formData.date} onChange={handleChange} style={inputStyle} />
              </th>
              <th style={cellStyle}>CYCLE</th>
              <th style={cellStyle}>
                <select
                  value={option}
                  onChange={handleOptionChange}
                  style={inputStyle}
                >
                 <option value="">Select Option</option>
      <option value="1-10">1-10</option>
      <option value="11-15">11-15</option>
      <option value="15-20">15-20</option>
      <option value="21-30">21-30</option>
                </select>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={cellStyle}>REF:</td>
              <td style={cellStyle}>
                <input type="text" name="ref" value={formData.ref} onChange={handleRefSearchTermChange} style={inputStyle} />
                {refSearchResults.length > 0 && (
                  <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                    {refSearchResults.map((customer) => (
                      <li key={customer.formNo} style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>
                        <span onClick={() => {
                          setFormData((prev) => ({ ...prev, ref: customer.customerName }));
                          setRefSearchTerm(customer.customerName);
                          setRefSearchResults([]);
                        }}>{customer.customerName}</span>
                      </li>
                    ))}
                    <li key="no-match" style={{ padding: "10px", borderBottom: "1px solid #ccc" }}>
                      <span onClick={() => {
                        setFormData((prev) => ({ ...prev, ref: refSearchTerm }));
                        setRefSearchResults([]);
                      }}>No match found, use this value</span>
                    </li>
                  </ul>
                )}
              </td>
            </tr>
            <tr>
              <td style={cellStyle}>INVESTOR NAME:</td>
              <td style={cellStyle} colSpan="6">
                <input type="text" name="investorName" value={formData.investorName} onChange={handleChange} style={inputStyle} />
              </td>
            </tr>
            <tr>
              <td style={cellStyle}>AMOUNT IN NUMBER:</td>
              <td style={cellStyle} colSpan="6">
                <input type="number" name="amountInNumber" value={formData.amountInNumber} onChange={handleChange} style={inputStyle} />
              </td>
            </tr>
            <tr>
              <td style={cellStyle}>AMOUNT IN WORD:</td>
              <td style={cellStyle} colSpan="6">
                <input type="text" name="amountInWord" value={formData.amountInWord} readOnly style={inputStyle} />
              </td>
            </tr>
            <tr>
              <td style={cellStyle}>MODE OF PAYMENT:</td>
              <td style={cellStyle}>
                <select
                  value={paymentMode}
                  onChange={handlePaymentModeChange}
                  style={inputStyle}
                >
                  <option value="cash">Cash</option>
                  <option value="online">Online</option>
                </select>
              </td>
              <td style={cellStyle}>
                {paymentMode === "online" ? "Enter Bank Details" : ""}
              </td>
            </tr>
            {paymentMode === "online" && (
              <>
                <tr>
                  <td style={cellStyle} colSpan="7">
                    <h3>Bank Account Details</h3>
                  </td>
                </tr>
                <tr>
                  <td style={cellStyle}>ACCOUNT NAME:</td>
                  <td style={cellStyle} colSpan="3">
                    <input type="text" name="accountName" value={formData.accountName} onChange={handleChange} style={inputStyle} />
                  </td>
                </tr>
                <tr>
                  <td style={cellStyle}>BANK NAME:</td>
                  <td style={cellStyle} colSpan="6">
                    <input type="text" name="bankName" value={formData.bankName} onChange={handleChange} style={inputStyle} />
                  </td>
                </tr>
                <tr>
                  <td style={cellStyle}>ACCOUNT NO:</td>
                  <td style={cellStyle} colSpan="6">
                    <input type="text" name="accountNo" value={formData.accountNo} onChange={handleChange} style={inputStyle} />
                  </td>
                </tr>
                <tr>
                  <td style={cellStyle}>BRANCH:</td>
                  <td style={cellStyle} colSpan="6">
                    <input type="text" name="branch" value={formData.branch} onChange={handleChange} style={inputStyle} />
                  </td>
                </tr>
                <tr>
                  <td style={cellStyle}>IFSC CODE:</td>
                  <td style={cellStyle} colSpan="6">
                    <input type="text" name="ifscCode" value={formData.ifscCode} onChange={handleChange} style={inputStyle} />
                  </td>
                </tr>
                <tr>
                  <td style={cellStyle}>GOOGLE PAY NO:</td>
                  <td style={cellStyle} colSpan="3">
                    <input type="text" name="googlePayNo" value={formData.googlePayNo} onChange={handleChange} style={inputStyle} />
                  </td>
                  <td style={cellStyle}>ACCOUNT TYPE:</td>
                  <td style={cellStyle} colSpan="2">
                    <input type="text" name="accountType" value={formData.accountType} onChange={handleChange} style={inputStyle} />
                  </td>
                </tr>
              </>
            )}
            <tr>
              <td style={cellStyle}>TAKEN BY:</td>
              <td style={cellStyle} colSpan="4">
                < input type="text" name="takenBy" value={formData.takenBy} onChange={handleChange} style={inputStyle} />
              </td>
            
            </tr>
          </tbody>
        </table>
        <button type="submit" style={{ padding: "10px", backgroundColor: "#4CAF50", color: "white", border: "none", borderRadius: "5px", cursor: "pointer", fontSize: "16px", width: "50%", display: "block", margin: "0 auto" }}>
          {isUpdate ? "Update" : "Submit"}
        </button>
      </form>

      <div>
        <h2>Search Customers</h2>
        <input type="text" value={searchTerm} onChange={handleSearchTermChange} placeholder="Search by receipt no or investor name" />
      </div>

      <table
        style={{
          border: "1px solid black",
          borderCollapse: "collapse",
          width: "100%",
        }}
      >
        <thead>
          <tr>
            <th style={cellStyle}>Receipt No</th>
            <th style={cellStyle}>Investor Name</th>
            <th style={cellStyle}>Amount</th>
            <th style={cellStyle}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {customers.filter(customer => customer.receiptNo.toString().includes(searchTerm) || customer.investorName.toLowerCase().includes(searchTerm.toLowerCase())).map(customer => (
            <tr key={customer.receiptNo}>
              <td style={cellStyle}>{customer.receiptNo}</td>
              <td style={cellStyle}>{customer.investorName}</td>
              <td style={cellStyle}>{customer.amountInNumber}</td>
              <td style={cellStyle}>
                <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                  <button onClick={() => printForm(customer)} style={{ backgroundColor: '#4CAF50', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer', padding: '10px 20px', fontSize: '16px', display: 'inline-block', verticalAlign: 'middle' }}>
                    <i className="fa-solid fa-print"></i>
                  </button>
                  <button onClick={() => updateCustomerForm(customer)} style={{ backgroundColor: '#03A9F4', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer', padding: '10px 20px', fontSize: '16px', display: 'inline-block', verticalAlign: 'middle', marginLeft: '10px' }}>
                    <i className="fas fa-edit"></i>
                  </button>
                  <button onClick={() => deleteCustomer(customer)} style={{ backgroundColor: '#FF0000', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer', padding: '10px 20px', fontSize: '16px', display: 'inline-block', verticalAlign: 'middle', marginLeft: '10px' }}>
                    <i className="fas fa-trash-alt"></i>
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ToastContainer />
    </div>
  );
}

export default InvestmentForm;